.App {
  background-color: #f1f1f1;
  min-height: 100vh;
  width: 100vw;
  font-family: "Fredoka", sans-serif;
}

.country-dropdown {
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  height: 40px;
}
